import React, { useState, useContext } from "react";
import { useNavigate  } from "react-router-dom";
import { AppContext } from "../Components/AppContext";
import Constants from "../Utilities/Constants";


const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [cellCode, setCellCode] = useState('');
    const [customerId, setCustomerId] = useState('');
    const { setCustomer } = useContext(AppContext);
    const navigate = useNavigate();

    
    const cellLogin = async () => {
        
        // Login by cellular code
        var verificationCode = cellCode;

        try {
            const url = Constants.API_URL_GET_USER_LOGIN;
            const data = { customerId, verificationCode };
            
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            };
    
            const json = await fetch(url, options).then((response) => response.json());

            if (json.status === 400) {
                alert('שם משתמש או סיסמה לא נכונים');
            }
            else{
                setCustomer(json);
                document.getElementById("getCode").style.display="none";
                document.getElementById("getPassword").style.display="block";
                }
            }
        catch (error) {
            console.error(error);
        }
    }

    const updatePassword = async () => {
        
        // Login by cellular code
        if (password1 !== password2) {
            warning("אין התאמה בין הססמאות");
        }
        else {  
            const hash = await handleHash(password1);

            try {
                setCustomerId(parseInt(customerId));
                const url = Constants.API_URL_RESET_PASSWORD;
                const data = { customerId, hash };
                
                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body: JSON.stringify(data)
                };

                const json = await fetch(url, options).then((response) => response.json());

                if (json.status === 400) {
                    warning('סיסמה לא עודכנה');
                }
                else {
                    alert("הסיסמה שונתה בהצלחה!")
                    navigate('/');
                }
            }
            catch (error) {
                console.error(error);
            }
        }
    }
   
    const handleHash = async (text) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return sha256Hash;
    }

    function warning(msg) {
        var warning = document.getElementById('password-warning');
        warning.textContent = msg;
        warning.style.display = 'block';
    }

    async function sendEmail()
    {
        const url = `${Constants.API_URL_GET_CODE}/?email=${email}`;

        try {
            await fetch(url).then(response => response.text())
            .then((text) => {
                setCustomerId(text);
                document.getElementById("getCell").style.display="none";
                document.getElementById("getCode").style.display="block";
            })
            .catch(() => alert("מספר הטלפון לא קיים במערכת"))
        }
        catch (error) {
            console.log(error);
        }
    }


    return (
        <>
        <h1 className="m-5">איפוס סיסמה</h1>

        <div className="d-flex justify-content-center align-items-center w-100" style={{backgroundColor: '#c4cd96'}}>
            <div className="w-50 p-5 rounded shadow bg-light" style={{backgroundColor: '#f2f2f2'}}>

               {/* <form name="signByCell" id="signByCell"  > */}

                    <div id="getCell" >
                        {/* <!-- Email input --> */}
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="email">כתובת האימייל הרשומה באתר</label>
                            <input type="email" id="email" className="form-control" 
                                onChange={(e)=>setEmail(e.target.value.trim())} />
                        </div>

                        <div className="d-grid mt-4">
                            <button type="button" id="btn-send-phone" className="btn btn-primary" 
                                onClick={()=>sendEmail()} >שלח לי קוד לאימייל</button>
                        </div>
                    </div>
                        
                    <div id="getCode" className="hidden" >
                        {/* <!--  Accept code sent to email  --> */}
                        <div className="form-outline" >
                            <label className="form-label" htmlFor="email">קוד כניסה נשלח בדואר אלקטרוני. נא הקש את הקוד שהתקבל במייל ולחץ אישור.</label>
                            <input name="code" id="cellCode" type="text" className="form-control"
                                onChange={(e)=>setCellCode(e.target.value.trim())}  />
                        </div>

                        <div className="d-grid mt-4">
                            <button type="button" name="cellLogin"  className="btn btn-primary" 
                                onClick={()=>cellLogin()}>אישור</button>
                        </div>
                        
                    </div>

                    <div id="getPassword" className="hidden" >
                        {/* <!-- Password input --> */}
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="password">סיסמה חדשה</label>
                            <input type="password" id="password" name="password" className="form-control" autoComplete="current-password"
                                    onChange={(e)=>setPassword1(e.target.value)} />
                        </div>

                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="password">הקש שוב את הסיסמה</label>
                            <input type="password" id="password" name="password" className="form-control" autoComplete="current-password"
                                    onChange={(e)=>setPassword2(e.target.value)} />
                        </div>
                        <div className="mt-1" style={{height: "25px"}}>
                            <span id="password-warning" className="invalid-feedback"></span>
                        </div>

                        <div className="d-grid mt-4">
                            <button type="button" id="btn-send-phone" className="btn btn-primary" 
                                onClick={()=>updatePassword()} >עדכן סיסמה</button>
                        </div>
                    </div>

                {/* </form> */}

            </div>
        </div>
        </>
    )
}


export default ResetPassword;