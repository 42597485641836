import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Constants from "../Utilities/Constants";


const SideBarGallery = () => {
    const [ gallery, setGallery] = useState([]);

    useEffect(() => {

        const fetchGallery = async () => {
            var url = `${Constants.API_URL_GET_GALLERY}/פרסום-צד`;

            try {
                const response = await axios.get(url)
                setGallery(response.data);
            }
            catch(error) {
                console.error("גלריה לא נמצאה");
            }
        }

        fetchGallery();
        
    },[])


    return (
        gallery.map((image, key) => {
            return (
                <Link to={image.imageLink} className="w-100 p-2" key={key}>
                    <img src={`${Constants.IMAGE_URL}${image.fileName}`}
                        alt={image.imageTitle} />
                </Link>
            )
        })
    )
}

export default SideBarGallery;
