import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Constants from "../Utilities/Constants";
import axios from "axios";
import PersonalMenu from "../Components/PersonalMenu"


const Invoice = () => {
    const { orderid } = useParams();
    const [ invoice, setInvoice ] = useState('');

    useEffect(() => {
    
        getInvoice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    async function getInvoice() {
        const url = `${Constants.API_URL_GET_INVOICE}/${orderid}`;

        try {
            await axios.get(url)
                .then((response) => setInvoice(response.data));

            //setTotal(response.data.items.reduce((a,v) =>  a = a + v.itemQtty * v.itemPrice , 0 ).toFixed(2));
        }
        catch(error) {
            alert('העתק חשבון לא נמצא');
            console.log(error.message);
        }
    }

    const InvoiceHeader = () => {
        
        const item = invoice[0];
        
        return (

            <div className="d-flex justify-content-between bg-white p-4">
                
                <div>
                    <table>
                        <tbody>
                        <tr><td>{item.fullname}</td></tr>
                        <tr><td>{item.Address1}</td></tr>
                        <tr><td>{item.Address2}</td></tr>
                        </tbody>
                    </table>
                </div>

                <div >
                    <table>
                        <tbody>
                        <tr><td className="ps-2"> מס הזמנה: </td><td> {item.reference}</td></tr>
                        <tr><td> תאריך: </td><td> {item.ValueDate}</td></tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        )
    }

    const InvoiceDetails = () => {

        const DocumentID = "31"
        const AccountKey = "302000"
        const ItemKeyWTax = "1000"
        const ItemKeyNoTax = "2000"
        const TipKey = "3000"
        const DeliveryKey = "3002"
        const CouponKeyWTax = "4000"
        const CouponKeyNoTax = "4002"
        const ClubKeyWTax = "4004"
        const ClubKeyNoTax = "4005"
        const SpecialKeyWTax = "6000"
        const SpecialKeyNoTax= "6001"
    
        const taxFreeArray = [ItemKeyNoTax, CouponKeyNoTax, ClubKeyNoTax, SpecialKeyNoTax];

        const newlist = invoice.map((item) => {
            return ((taxFreeArray.includes(item.ItemKey)) 
                ? {...item, tax: 0, totalItem: (item.Price * item.Quantity).toFixed(2) } 
                : {...item, tax: (item.Price*0.17*item.Quantity).toFixed(2), totalItem: (item.Price*1.17 * item.Quantity).toFixed(2)});
        });

        var totalInvoice = newlist.reduce((a,v) =>  a = a + parseFloat(v.totalItem) , 0 );
        var totalTax = newlist.reduce((a,v) =>  a = a + parseFloat(v.tax) , 0 );

        return (
            <table className="table table-striped1 order-items">
                <thead  className="text-center;">
                <tr>
                    <th>שם המוצר</th>
                    <th>כמות שהוזמנה</th>
                    <th>כמות שסופקה</th>
                    <th>מחיר</th>
                    <th>סה"כ</th>
                </tr>
                </thead>
                <tbody>
                    {newlist.map((item, idx) => {
                        return (
                            <tr key={idx} className={(item.Quantity < 0) ? 'fw-bold' : ''}>
                                <td >{item.ItemName}</td>
                                <td >{item.Remarks}</td>
                                <td >
                                    {(item.Quantity > 0) && <>
                                     {(item.Unit === '0.0') ? parseInt(item.Quantity) : item.Quantity}
                                        &nbsp;{(item.Unit === '0.0') ? "יח'" : 'ק"ג'}
                                        </>}
                                </td>
                                <td >{(item.Price*1.17).toFixed(2)}</td>
                                <td >{item.totalItem}</td>
                            </tr>
                        )
                    })}
                    
                    <tr>
                        <td className="title text-start" colSpan="4">סה"כ קנייה ללא מע"מ:</td>
                        <td className="content">{(totalInvoice-totalTax).toFixed(2)} ₪</td>
                    </tr>
                    <tr>
                        <td className="title text-start" colSpan="4">מע"מ:</td>
                        <td className="content">{totalTax.toFixed(2)} ₪</td>
                    </tr>
                    <tr>
                        <td className="title text-start" colSpan="4">סה"כ לתשלום:</td>
                        <td className="content">{totalInvoice.toFixed(2)} ₪</td>
                    </tr>
                </tbody>
            </table>
        )
    }


    return (
        <>
        <h3 className="m-5 text-center">פירוט משלוח</h3>
        <div className="container ps-5">
            <div className="row">
                <div className="col-md-3 vertical-nav">
                    <PersonalMenu />
                </div>
                <div className="col-md-9">
                    {invoice && <InvoiceHeader />}
                    <br></br>
                    {invoice && <InvoiceDetails /> }
                </div>
            </div>
        </div>
        </>
    )  
}

export default Invoice;