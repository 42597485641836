import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "./AppContext";
import Constants from "../Utilities/Constants";
import axios from "axios";
import PageContent from "./Content";


const Footer = () => {
    const { customer } = useContext(AppContext);
   
    return (
        <footer >
            <div className="container">
            <div className="row BottomBar" >

                {/* Logo */}
                <div className="col-md-2">
                    <div className=" logo">
                        <img src={`${Constants.IMAGE_URL}/uploads/logo.webp`} alt="Logo" />
                    </div>
                </div>
                
                {/* Categories */}
                <div className="col-md-4 BottomCategories" >
                    <div className="row row-cols-3">
                    <Categories business={customer && customer.business}/>
                    </div>
                </div>

                {/* Bottom menus */}
                <div className="col-md-4 BottomCategories">
                    <div className="row">
                        <div className="col-md-4">
                            <span className='fw-bold'>החשבון שלי</span>
                            <Menu name="החשבון שלי" />
                        </div>
                        <div className="col-md-4">
                            <span className='fw-bold'>שירות לקוחות</span>
                            <Menu  name="שירות לקוחות" />

                        </div>
                        <div className="col-md-4">
                            <span className='fw-bold'>בליקמח</span>
                            <Menu  name="בליקמח" />
                        </div>
                    </div>
                </div>

                {/* Bottom left corner */}
                <div className="col-md-2" >
                    <PageContent furl="להזמנת-משלוח" />
                </div>
    
            </div>

            <div className="d-flex justify-content-center align-items-center flex-wrap">
                <Link to="page/הצהרת-נגישות" >הצהרת נגישות</Link>  &nbsp;|&nbsp; 
                ניהול פרוייקט: קונספט- פתרונות פרסום |  &nbsp;
                <Link to="mailto:ozovadia@gmail.com" >בניית אתרים</Link>
                <img src="/img/oz_logo.png" className="logo" alt="Oz Aviad Logo"/>
                <Link to="mailto:ozovadia@gmail.com">עוז אביעד</Link>
            </div>
            </div>
        </footer>
    )
}

const Categories = ({business}) => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        
        async function getCategories () {
            const url = Constants.API_URL_GET_CATEGORIES;
            var api = url;
    
            if (business) {
                api = `${url}?b=true`;
            }
    
            await fetch(api).then(response => response.json())
                .then(data => setCategories(data));
        }

        getCategories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [business])

    return (
        categories.map((item, index) => (
            <Link key={index} to={{pathname:`/Category/${item.categoryUrl}`}}>{item.categoryName}</Link>
        ))
    )
}

const Menu = ({name}) => {
    const [menuItems, setMenuItems] = useState([]);
    
    useEffect(() => {
        
        async function getMenu() {
            const url = `${Constants.API_URL_GET_MENU}/${name}`;
            
            const response = await axios.get(url);
            setMenuItems(response.data);
        }

        getMenu();

    }, [name]); 

    return (
        <ul>
            {menuItems.map((item, index) => {
                return (
                    <li key={index}><a href={item.link}>{item.title}</a></li>
                )}
            )}
        </ul>
    )
}

export default Footer;