import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate  } from "react-router-dom";
import { AppContext } from "../Components/AppContext";
import Constants from "../Utilities/Constants";
import axios from "axios";
import { format } from "date-fns";
import PersonalMenu from "../Components/PersonalMenu"
import { Modal, Button } from 'react-bootstrap';


const Orders = () => {
    const [orders, setOrders] = useState([]);
    const {customer, cartId, setCart} = useContext(AppContext);
    const [orderId, setOrderId]  = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
    
        getOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    async function getOrders() {
        if (customer) {
            const url = `${Constants.API_URL_GET_ORDERS}/${customer.customerId}`;

            try {
                const response = await axios.get(url);
    
                setOrders(response.data);
            }
            catch(error) {
                console.log(error.message);
            }
    
        }
        else {
            alert('נא להיכנס למערכת');
            Navigate('/Login');
        }
    }

    const handleClose = () => setShowModal(false);

    const handleShow = () => setShowModal(true);

    const handleSave = () => {

       updateCart();

        handleClose(); // Optionally close the modal after saving
    }

    async function updateCart() {
        const url = `${Constants.API_URL_LOAD_CART}/${cartId}/${orderId}`;

        try {
            const response =  await axios.get(url);
    
            setCart(response.data);
        }
        catch(error) {

            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                alert('הזמנה לא נמצאה');
            } else
                alert(error.message);
        }
    }

    const OrderRow = ({order}) => {

        var formattedDate = format(order.orderDate, "dd/MM/yyyy");

        return (
            <tr >
                <td className="desktop">{order.orderId}</td>
                <td>{formattedDate}</td>
                <td>{order.totalOrder}</td>
                <td className="desktop">{order.status}</td>
                <td className="text-center">
                    <Link to={`/Order/${order.orderId}`} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 9h14V7H3v2zm0 4h14v-2H3v2zm0 4h14v-2H3v2zm16 0h2v-2h-2v2zm0-10v2h2V7h-2zm0 6h2v-2h-2v2z"/></svg>
                    </Link>
                    </td>
                <td className="text-center">
                    <Link to={`/Invoice/${order.orderId}`} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><g><rect fill="none" height="24" width="24"/></g><g><path d="M4,7h16v2H4V7z M4,13h16v-2H4V13z M4,17h7v-2H4V17z M4,21h7v-2H4V21z M15.41,18.17L14,16.75l-1.41,1.41L15.41,21L20,16.42 L18.58,15L15.41,18.17z M4,3v2h16V3H4z"/></g></svg>
                    </Link>
                </td>
                <td className="text-center">
                    <button onClick={()=>{setOrderId(order.orderId); handleShow();}} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
                    </button>
                </td>
            </tr>
        )
    }

    const OrderTable = () => {
        return (

            <table className="table table-striped">

                <thead>
                <tr className="head">
                    <th className="desktop">הזמנה</th>
                    <th>תאריך</th>
                    <th>סכום</th>
                    <th className="desktop">סטטוס</th>
                    <th className="text-center">הצג הזמנה</th>
                    <th className="text-center">העתק חשבון</th>
                    <th className="text-center">שחזר הזמנה</th>
                </tr>
                </thead>
            
                <tbody>
                    {orders.map((order, key) => {
                        return (
                            <OrderRow key={key} order={order} />
                        )
                    })}
                </tbody>
            
            </table>

        )
    }


    const Duplicate = ({ show, handleClose, handleSave }) => {

        return (
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>שחזור הזמנה</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    שים לב:<br></br>
                    פעולה זו תנקה את סל הקניות ותטען מוצרים חדשים עפ"י ההזמנה שבחרת.<p></p>
                    לאישור הקש אישור, לביטול הקש בטל.
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                ביטול
                </Button>
                <Button variant="primary" onClick={handleSave}>
                אישור
                </Button>
            </Modal.Footer>
        </Modal>

        //   <div className="modal fade" id="duplicateModal" tabIndex="-1" aria-labelledby="duplicateModalLabel" aria-hidden="true">
        //     <div className="modal-dialog modal-dialog-centered">
        //         <div className="modal-content">
        //         <div className="modal-header">
        //             <h1 className="modal-title fs-5" id="duplicateModalLabel">שחזור הזמנה</h1>
        //             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        //         </div>
        //         <div className="modal-body">
        //             שים לב:<br></br>
        //             פעולה זו תנקה את סל הקניות ותטען מוצרים חדשים עפ"י ההזמנה שבחרת.<p></p>
        //             לאישור הקש אישור, לביטול הקש בטל.
        //         </div>
        //         <div className="modal-footer">
        //             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">ביטול</button>
        //             <button type="button" className="btn btn-primary"  data-bs-dismiss="modal" onClick={()=>LoadOrder()}>אישור</button>
        //         </div>
        //         </div>
        //     </div>
        // </div>
        )
    }


    return (
        <>      
        <h3 className="m-5 text-center">ההזמנות שלי</h3>
        <div className="container">
            <div className="row">
                <div className="col-md-3 vertical-nav">
                    <PersonalMenu />
                </div>
                <div className="col-md-9">
                    <OrderTable />
                </div>
            </div>
        </div>
        <Duplicate show={showModal} handleClose={handleClose} handleSave={handleSave} />
        </>
    )
}

export default Orders;
