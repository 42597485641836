import React, { useState, useRef } from "react";
import { useParams  } from "react-router-dom";
import PageContent from "../Components/Content";
import Constants from "../Utilities/Constants";
import axios from "axios";


const ContactForm = () => {
    const { url } = useParams();
    const [inputs, setInputs] = useState({});
    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const [sent, setSent] = useState(false);


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = async (event) => {
        
        event.preventDefault();
        var HtmlBody = 
            `<div style='direction: rtl'<h1>פנייה דרך צור קשר באתר</h1><p><strong>שם השולח:</strong> ${inputs.name}</p><p><strong>דוא"ל:</strong> ${inputs.email}</p><p><strong>טלפון:</strong> ${inputs.phoneNo}</p><p><strong>תוכן הפנייה:</strong> ${inputs.notes}</p></div>`;

        inputs['htmlBody'] = HtmlBody;
        postForm(inputs);
    }

    const postForm = async (form) => {
        try {
            console.log(form);
            const url = Constants.API_URL_SEND_EMAIL;
            const response = await axios.post(url, form);
            setSent(true);
        }
        catch(e) {
            alert(e);
        }
    }

    function validateCellular(cellPhone) {
        const regex = /^0(5[012345678]|6[47]){1}(-)?[^0\D]{1}\d{6}$/;
        
        if (cellPhone === '' || regex.test(cellPhone))
        {
            document.getElementById("phoneError").style.display= "none";
            return true;
        } 
        else {
            var field = document.getElementById("phoneError");
            field.style.display= "block";
            phoneRef.current.focus();
            return false;
        }
    }

    function validateEmail(email) {
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        
        if (email === '' || regex.test(email))
        {
            document.getElementById("emailError").style.display= "none";
            return true;
        } 
        else {
            var field = document.getElementById("emailError");
            field.style.display= "block";
            emailRef.current.focus();
            return false;
        }
    }

    


    const ShowThankYou = () => {
        return (
            <div className="d-flex d-wrap justify-content-center align-items-center flex-column">
                <h2>תודה על פנייתך</h2>
                <br/>
                <span>נשוב אליך בהקדם</span>
                <br />
            </div>
        )
    }

    return (
        <div className="container">
            <h3 className="m-5 text-center">כתבו לנו...</h3>
            <div className="wrapper d-flex flex-wrap justify-content-center align-items-center w-100 ">
                <div>
                    <PageContent furl={url} />
                </div>

                <div>
                    { sent ? 
                        <ShowThankYou />
                    :
                    <form method="post">
                        <div className="row">
                            <div className="text-danger"></div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="name" className="form-label">שם מלא</label>
                                    <input name="name" id="name" className="form-control" onBlur={handleChange} />
                                    <span className="text-danger"></span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="phoneNo" className="form-label">טלפון</label>
                                    <input name="phoneNo" id="phoneNo" className="form-control" ref={phoneRef}
                                    onChange={handleChange} onBlur={(e)=> validateCellular(e.target.value)} />
                                    <span id="phoneError" className="text-danger">נא הקש מספר טלפון תקין</span>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="email" className="form-label">דואר אלקטרוני</label>
                                    <input name="email" id="email" className="form-control"  ref={emailRef} 
                                        onChange={handleChange} onBlur={(e)=> validateEmail(e.target.value)} />
                                    <span id="emailError" className="text-danger">נא הקש כתובת דואר אלקטרוני תקין</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="notes" className="form-label">הפניה שלי</label>
                                    <textarea name="notes" id="notes" className="form-control" onBlur={handleChange} />
                                </div>
                            </div>

                        </div>

                        <div className="buttons mt-4">
                            <input type="submit" value="שלח" className="btn btn-primary" onClick={handleSubmit} />
                        </div>
                    </form>
                    }
                </div>      
            </div>
        </div>
    )
}


export default ContactForm;