import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import MmenuLight from 'mmenu-light';
import { AppContext } from "./AppContext";
import 'mmenu-light/dist/mmenu-light.css';
import Constants from '../Utilities/Constants';
import '../css/custom.css';


const SmartMenu = () => {
  const { customer } = useContext(AppContext);

  useEffect(() => {
        // Initialize the menu when component mounts
        const menu = new MmenuLight(document.querySelector("#menu"));
        menu.navigation({
          slidingSubmenus: false,
          title: 'תפריט'});
        const drawer = menu.offcanvas({ 
          position: 'right'
         });

        // Open menu when button is clicked
        const openMenuButton = document.querySelector('a[href="#menu"]')
          .addEventListener("click", (event) => {
                event.preventDefault();
                drawer.open();
            });
        
        // Clean up event listener on component unmount
        return () => {
            if (openMenuButton) {
                openMenuButton.removeEventListener("click", drawer.open);
            }
        };
    }, []);

    return (
      <NavBar business={customer && customer.business}/>
    );
};

const NavBar = ({business}) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
      
      async function getCategories () {
          const url = Constants.API_URL_GET_CATEGORIES;
          var api = url;
  
          if (business) {
              api = `${url}?b=true`;
          }
  
          await fetch(api).then(response => response.json())
              .then(data => setCategories(data));
      }

      getCategories();

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business])


  return (
    <nav id="menu" aria-hidden="true" className='mm-menu'>
        <ul>
          <li className="mm-listitem">
            
            <Link className="m-login mm-listitem__text" to={"/Login"}>

              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" className="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
              &nbsp;&nbsp;כניסה לחשבון&nbsp;

              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-left" className="svg-inline--fa fa-angle-left fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>
            </Link>
            
          </li>
          <li className="icons-panel mm-listitem w-100 d-flex justify-content-between">
              <a href="/page/איזורי-משלוח" className="top mm-listitem__text col">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="truck" className="svg-inline--fa fa-truck fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 640 512"><path fill="currentColor" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path></svg>
                <br />
                <span>איזורי משלוח</span>
              </a>
              <a href="/orders.asp" className="top mm-listitem__text col">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync-alt" className="svg-inline--fa fa-sync-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"></path></svg>
                <br />
                <span>שחזור הזמנה</span>
              </a>
              <a href="/category/מבצעי-השבוע" className="top mm-listitem__text col">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bullhorn" className="svg-inline--fa fa-bullhorn fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 576 512"><path fill="currentColor" d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"></path></svg>
                <br /><span>מבצעים</span>
              </a>
          </li>
          {
              categories.map((item, index) => (
                <li key={index}>
                      <Link to={{pathname:`/Category/${item.categoryUrl}`}}>{item.categoryName}</Link>
                      <ul className='drop-down'>
                          <div className="clearfix column-2">
                          {
                              item.subCategories.map((node, idx) => (
                                  <li  key={idx}>
                                      <div className="drop_link">
                                          <Link className="dropdown-item" to={{pathname:`/Category/${item.categoryUrl}/${node.subCategoryUrl}`}}>{node.subCategoryName}</Link>
                                      </div>
                                  </li>
                              ))
                          }
                          </div>
                      </ul>
                </li>
              ))
          }
          <li> 
              <Link to={{pathname:`/Category/sale`}}>מבצעי השבוע</Link>
          </li>
        </ul>
    </nav>
  )
}


export default SmartMenu;
