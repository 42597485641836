import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Constants from "../Utilities/Constants";
import { AppContext } from "../Components/AppContext";
import QttyAction from "../Components/QttyAction";
import axios from "axios";


const Cart = () => {
    const { cart } = useContext(AppContext);
    const [ totalCart, setTotalCart ] = useState(0);


    useEffect(()=>{
        var total = cart.reduce((a,v) =>  a = a + v.itemTotal , 0 );
        setTotalCart(total);
    },[cart]);

    const DesktopCart = () => {
        return (
            <div className="container">
                <div className="p-5 m-3 rounded shadow bg-white">
                    <div className="d-flex justify-content-between border-bottom border-success">
                        <h4 className="">סל הקניות שלי</h4>
                    </div>
                    <br/>
                    <div className="d-flex justify-content-center align-items-center">
                        <table className="table order-items">
                            <thead className="text-center">
                                <tr>
                                    <th>תמונה</th>
                                    <th >שם המוצר</th>
                                    <th className="desktop">כמות</th>
                                    <th>יחידה</th>
                                    {/* <th>מחיר</th> */}
                                    <th>סה"כ</th>
                                    {/* <th>הערות</th> */}
                                    <th className="desktop cell">מחיקה</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cart.map((item) => {
                                return (
                                    <CartItem item={item} key={item.itemId} />
                                )
                                })}
                                
                                <tr>
                                    <td colSpan="3" >{cart.length} מוצרים בסל</td>
                                    <td className="text-start" colSpan="1" >סה"כ סל הקניות:</td>
                                    <td className="content" colSpan="2">{totalCart.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <Link to="/Cash" className="btn btn-primary"> יאללה, לקופה! </Link>  
                </div>
            </div>
        )
    }

    const SmartCart = () => {
        return (
            <div className="container">
                <div className="d-flex justify-content-between border-bottom border-success pt-3">
                    <h4 className="">סל הקניות שלי</h4>
                </div>
                <br/>
                <div className="d-flex justify-content-center align-items-center">
                    <table className="table order-items">
                        <thead className="text-center">
                            <tr>
                                <th>תמונה</th>
                                <th >שם המוצר</th>
                                <th className="desktop">כמות</th>
                                {/* <th>יחידה</th> */}
                                {/* <th>מחיר</th> */}
                                <th>סה"כ</th>
                                {/* <th>הערות</th> */}
                                <th className="desktop cell">מחיקה</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart.map((item) => {
                            return (
                                <CartItem item={item} key={item.itemId} />
                            )
                            })}
                            
                            <tr>
                                <td colSpan="3" >{cart.length} מוצרים בסל</td>
                            </tr>
                            <tr>
                                <td className="text-end" colSpan="2" >סה"כ סל הקניות:</td>
                                <td className="content" >{totalCart.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <Link to="/Cash" className="btn btn-primary"> יאללה, לקופה! </Link>  
            </div>
        )
    }

    return (
        (window.innerWidth > 768) 
        ? <DesktopCart />
        : <SmartCart />
    )
}


const CartItem = ({item}) => {
    const { cart, setCart } = useContext(AppContext);

    console.log('item',item.product);
    if (item.product.units.length > 1) 
        {
            const newlist = item.product.units.map((x) => {
                return ((x.unitId === item.unitId) ? {...x, active: "active"} : {...x, active: ""});
            });
            item.product = {...item.product, units: newlist};
        }
    else item.product.units[0].active = 'active';


    const Units = ({units}) => {

        return (
            <div className="unit-types rounded-pill mx-auto text-center">
            { 
                units.length > 1
                    
                    ? units.map((unit, idx) => {
                
                        return (
                            <button key={idx} 
                                className={`float-end rounded-pill units ${unit.active}`}
                                style={{width: `${unit.active === 'active' ? '60%' : '40%' }`}} 
                                onClick={() => toggleUnit(unit.unitId)}>
                                {unit.unitName}
                            </button>
                        )

                    })
                    :  <div>{item.unit.unitName}</div>
            }
            </div>
        )
    }

    const increment = () => {
        var qtty = item.itemQtty + item.unit.unitIncrement;
        updateDB(item.unit.unitId, qtty, item.notes);
    }
    
    const decrement = () => {
        var qtty = item.itemQtty - item.unit.unitIncrement;
        updateDB(item.unit.unitId, qtty, item.notes);
    }

    // const updateNotes = (e) => {
    //     updateDB(item.unit.unitId, item.itemQtty, e.target.value);
    // }

    async function updateDB (unitid, qtty, notes) {

        // Update db cart item with new qtty.
        // Receive updated cart item.
        // Update cart list with updated item.

        const apiUpdate = `${Constants.API_URL_UPDATE_CART}/${item.cartId}`;
        const data = {productId: item.productId, unitId: unitid, itemQtty: qtty, notes: notes };
        
        const response = await axios.post(apiUpdate, data);

        (qtty === 0) ? handleRemove(item.id) : handleUpdate(response.data);
    }

    async function handleUpdate(cartitem) {

        // Create new cart list with updated item
        const cartList = cart.map((x) => {
            return ((x.id === cartitem.id) ? cartitem : x);
        })
        
        setCart(cartList);
    }
    
    async function handleRemove(id) {
        
        // Remove item from cart list
        const newList = cart.filter((x) => x.id !== id);
        setCart(newList);
    }

    const toggleUnit = (unitid) => {

        const newlist = item.product.units.map((x,idx) => {
            return ((x.unitId === unitid) ? {...x, active: "active"} : {...x, active: ""});
        });

        const unit = newlist.find((unit) => unit.active === 'active');
        if (Number.isInteger(unit.unitIncrement)) 
        {   
            item.itemQtty = parseInt(item.itemQtty);
        }

        updateDB(unitid, item.itemQtty, item.notes);
    }

    const DesktopItem = () => {
        console.log(item);
        return (
            <tr className="item" key={item.itemId} >
                <td className="image text-center" >
                    <Link to={{pathname:`/Product/${item.product.productUrl}`}}>
                        <img src={`${Constants.SITE_URL}${item.product.image}`} alt={item.product.productName}/>
                    </Link>
                </td>
                <td >
                    <Link to={{pathname:`/Product/${item.productId}`}}>
                        {item.product.productName}
                    </Link>
                    <br/>
                    <div style={{fontSize: 12}}>
                        {item.product.price.toFixed(2)} ל{item.unit.unitName}
                    </div>
                    {item.product.special && showIngredients(item.selectedIngredients)}
                    {/* <div className="notes text-primary">{item.itemNotes}</div> */}
                </td>
                <td className="text-center" >
                    <div className="actions d-inline-block ">
                        <QttyAction item={item} increment={increment} decrement = {decrement} />
                    </div>
                </td>
                <td>
                    <div className="actions">
                        <Units units={item.product.units}/>
                    </div>
                </td>
                <td className="position-relative" >
                    {item.itemTotal.toFixed(2)}
                </td>
                {/* <td className="text-center">
                    <input type="text" className="notes" defaultValue={item.itemNotes} 
                        onBlur={updateNotes} />
                </td> */}
                <td className="desktop cell text-center">
                    <button className="remove desktop" onClick={() => updateDB(item.unitId,0,null)} />
                </td>
            </tr>
        )
    }

    const SmartItem = () => {

        return (
            <tr className="item" key={item.itemId} >
                <td className="image" >
                    <Link to={{pathname:`/Product/${item.product.productUrl}`}}>
                        <img src={`${Constants.SITE_URL}${item.product.image}`} alt={item.product.productName}/>
                    </Link>
                </td>
                <td className="text-right">
                    <Link to={{pathname:`/Product/${item.product.productUrl}`}}>
                        <div>
                            {item.product.productName}
                        </div>
                        <div style={{fontSize: 12}}>
                            {item.product.price.toFixed(2)} ל{item.unit.unitName}
                        </div>
                    </Link>
                    {item.product.special && showIngredients(item.selectedIngredients)}
                    {/* <div className="notes text-primary">{item.itemNotes}</div> */}
                    <br /> 
                    <div className="actions mt-2">
                        <QttyAction item={item} increment={increment} decrement = {decrement} />
                    </div>
                </td>
                <td className="position-relative" >
                    {item.itemTotal.toFixed(2)}
                    <button className="remove cellular" onClick={() => updateDB(item.unitId,0,null)} />
                </td>
            </tr>
        )
    }


    return (
        (window.innerWidth > 768) 
        ? <DesktopItem />
        : <SmartItem />
    )
}


const showIngredients = (ingredients) => {

    var ings = JSON.parse(ingredients);

    return (
        (ings != null) ?
            ings.map((ing, idx) => {
                return (
                    <div key={idx} className="text-success">{ing.ingredientName}</div>
                )
            })
        : ''
    )
}


export default Cart;