import { useState } from "react";
import Constants from "../Utilities/Constants";
import SmartMenu from "./SmartMenu";
import { Link } from "react-router-dom";


const SmartHeader = () => {
    
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };


    return (
        <div>
            <header className="smart d-flex justify-content-around align-items-center">
        
                {/* smart header */}  
                <a href="#menu" className="menu-button"><span className="hamburger"></span></a>

                <a className="logo" href="/" ><img src={`${Constants.IMAGE_URL}/uploads/logo.webp`} alt="logo" /></a>
                
                <button className="search-icon" onClick={toggleVisibility} >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><path fill="#20361D" d="M15.2 13.6l-3.7-3.7c.7-1 1.1-2.2 1.1-3.6C12.6 2.8 9.7 0 6.3 0S0 2.8 0 6.3s2.8 6.3 6.3 6.3c1.3 0 2.5-.4 3.5-1.1l3.7 3.7c.4.4 1.2.4 1.6 0 .5-.5.5-1.2.1-1.6zm-8.9-3c-2.4 0-4.3-2-4.3-4.3S3.9 2 6.3 2s4.3 1.9 4.3 4.3-2 4.3-4.3 4.3z"></path></svg>
                </button>
                
                <div className="totals fly-to position-relative pt-2">
                <Link className="cart-icon"to={{pathname:`/Cart`}}> 
                    <svg width="32" height="32" viewBox="0 0 16 16" className="bi bi-cart3" fill="black" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                    </svg>
                    <div id="TotalItems" ></div>
                    </Link>
                </div>
                
                {isVisible && (
                <div className='SmartSearch' id='SmartSearch' >
                    <form name="formSearchSmart" className="form-inline headerSearchFront" action="search.asp" method="post" onSubmit="return validateSearchSmart();">	
                        <input type="text" className="headerSearchInput" placeholder="חפש מוצר" id="searchInputSmart" name="searchText" autoComplete="off" aria-autocomplete="list" aria-haspopup="true" />
                        <input type="submit" className="left headerSearchSubmit" value="" />
                    </form>
                </div>
                )}
            </header>

            <div id="search-buff"></div> 
            
            <SmartMenu />
        </div>
    )
}


export default SmartHeader;
