import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Constants from "../Utilities/Constants";
import axios from "axios";
import { AppContext } from "../Components/AppContext";


const Duplicate = () => {
    const { orderid } = useParams();
    const { cartId, setCart} = useContext(AppContext);


    async function loadOrder() {
        const url = `${Constants.API_URL_LOAD_CART}/${cartId}/${orderid}`;

        try {
            const response = await axios.get(url);

            setCart(response.data);
            //setTotal(response.data.items.reduce((a,v) =>  a = a + v.itemQtty * v.itemPrice , 0 ).toFixed(2));
        }
        catch(error) {
            //alert('הזמנה לא נמצאה');
            console.log(error.message);
        }
        
        return (
            <div>Loading...</div>
        )
    }   

    return (

        <>
        {/* <!-- Button trigger modal --> */}
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
          Launch demo modal
        </button>
        
        {/* <!-- Modal --> */}
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">שחזור הזמנה</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                שים לב:<br></br>
                פעולה זו תנקה את סל הקניות ותטען מוצרים חדשים עפ"י ההזמנה שבחרת.<p></p>
                לאישור הקש אישור, לביטול הקש בטל.
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">ביטול</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={loadOrder}>אישור</button>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}

export default Duplicate;