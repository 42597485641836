import React, { useState, useEffect, createContext } from "react";
import GetCustomer from "./GetCustomer";


// Create a new context and export
export const AppContext = createContext();
 
// Create a Context Provider
export const AppContextProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [cartId, setCartId] = useState();
  const [customer, setCustomer] = useState();

  useEffect(() => {

    GetCustomer({ customer, setCustomer, setCart, setCartId });
       
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


    return (
      <AppContext.Provider value={{ customer, setCustomer, cart, setCart, cartId, setCartId,  }}>
          {children}
      </AppContext.Provider>
  );
}