import React from "react";
import { Link } from "react-router-dom";

const MyArea = () => {

    return (
        <ul>
        <li><Link className="btn" to="/Profile">פרטים כלליים</Link></li>
        <li><Link className="btn" to="/ChangePassword">החלפת סיסמה</Link></li>
        <li><Link className="btn" to="/Orders">ההזמנות שלי</Link></li>
        </ul>
    )
}

export default MyArea;