import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Constants from "../Utilities/Constants";
import Product from "../Components/Product";


const SearchProducts = () => {
    
    const [ products, setProducts] = useState([])
    const { query } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        setIsLoading(true);
        fetchProducts();
        setIsLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    if (isLoading) {
        return <div>Loading...</div>;
    }
    
    const fetchProducts = async () => {
        
        var url = `${Constants.API_URL_QUERY}/${query}`;

        try {
            const response = await axios.get(url);
            setProducts(response.data);
            console.log(response.data);
        }
        catch(error) {
            alert("לא נמצאו מוצרים בקטגוריה זו");
        }
    }


    return (
        <div className="container py-4">
            <div className="d-flex flex-wrap justify-content-around">
                {products.map((product, key) => {
                    return (
                        <Product product={product} key={key} />
                    )
                })}
            </div>
        </div>
    )
}


export default SearchProducts;
