import Constants from '../Utilities/Constants';
import axios from "axios";
import Cookies from 'js-cookie';


 const GetCustomer = async ({customer, setCustomer, setCart, setCartId}) => {

    const customerid = Cookies.get('customerid'); 
    if (customerid) {
        console.log('customer id found in cookie',customerid);
        FetchCustomer(customerid).then(data => setCustomer(data));
        FetchCart(customerid).then(data => setCart(data));
        setCartId(customerid);
        console.log(customer);
    }
    else {
      const userIp = await FetchUserIp();
      FetchCart(userIp).then(data => setCart(data));
      console.log('fetch user ip',userIp);
      setCartId(userIp);
      setCustomer(null);
    }
}

export const FetchUserIp = async () => {

    const url = Constants.API_URL_GET_USER_IP;
    var result = await fetch(url).then(response => response.text());

    console.log('fetchUserIp',result);
    return result;
}

export const FetchCustomer = async (customerid) => {
    
    console.log('fetch customer',customerid);
    const url = `${Constants.API_URL_GET_CUSTOMER}/?id=${customerid}`;
    try {
        const response = await axios.get(url);
        return response.data;
    }
    catch(err) {
        console.log('Cannot read customer');
        return null;
    }
}

export const FetchCart = async (cartid) => {
    
    console.log('fetch sidecart');
    const url = `${Constants.API_URL_GET_CART}/${cartid}`;
    try {
        const response = await axios.get(url);
        return response.data;
    }
    catch(err) {
        console.log('Cannot read cart');
        return null;
    }
}

export default GetCustomer;