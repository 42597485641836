import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../Components/AppContext";
import { useParams } from "react-router-dom";
import Constants from "../Utilities/Constants";
import axios from "axios";
import { format } from "date-fns";
import PersonalMenu from "../Components/PersonalMenu";


const Order = () => {
    const { orderid } = useParams();
    const { customer } = useContext(AppContext);
    const [ order, setOrder ] = useState('');
    const [ clubDiscount, setClubDiscount ] = useState(0);
    const [ totalOrder, setTotalOrder ] = useState(0);


    useEffect(() => {

        const getOrder = async () => {

            try {
                const url = `${Constants.API_URL_GET_ORDER}/${orderid}`;
                const response = await axios.get(url);
                setOrder(response.data);
            }
            catch(error) {
                alert('הזמנה לא נמצאה');
                console.log(error.message);
            }
        }
    
        getOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const OrderHeader = () => {
        return (
            <div className="d-flex justify-content-between m-2">
                
                <div>
                    <table className="table table-sm">
                        <tbody>
                        <tr><td>{order.customer.customerName}</td></tr>
                        <tr><td>{order.customer.phoneNo}</td></tr>
                        <tr><td>{order.phoneNo}</td></tr>
                        </tbody>
                    </table>
                </div>
                
                <h4 className="my-5">הזמנה {order.orderId}</h4>
                
                <div className="ms-5">
                    <table className="table table-sm">
                        <tbody>
                        {/* <tr><td> מס הזמנה: </td><td> {order.orderId}</td></tr> */}
                        <tr><td> תאריך הזמנה: </td><td> {format(order.orderDate,'dd/MM/yyy')}</td></tr>
                        <tr><td> תאריך משלוח: </td><td> {format(order.deliveryDate,'dd/MM/yyy')}</td></tr>
                        <tr><td>כתובת למשלוח: </td><td> {order.address}  {order.yishuv.yishuvName}</td></tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        )
    }

    const OrderFooter = () => {
        return (
            <div className="text-end">
                <div className="fw-bold">הערות להזמנה:</div>
                <div>{order.notes}</div>
                {order.replaceItem && <div>במקרה של חוסר, הוסיפו מוצר חלופי. אין צורך להתקשר.</div>}
                {/* {order.leaveOutside && <div>נא השאירו את הארגזים מחוץ לדלת</div>} */}
            </div>
        )
    }

    useEffect(() => {
        
        // If order is not closed recalculate club discount
        // (In case club due was renewed)
        if (order && order.orderStatus < 3) {
            calcClubDiscount();
        } else {
            setTotalOrder(order.totalOrder);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[order])


    const calcClubDiscount = async () => {
        
        const clubPercent =
            (customer.clubDue && customer.clubDue >= order.deliveryDate) ? customer.clubPercent : 0;

        const clubTotal = order.items.reduce((acc, curr) => {
            return curr.product.rebate ? acc + curr.itemPrice : acc;
        }, 0);
        
        const discount = clubPercent * clubTotal / 100;
        setClubDiscount(discount);
        setTotalOrder(order.totalCart + order.deliveryFee - order.discount - discount + order.tip);
    }


    const OrderDetails = () => {

        return (
            <table className="table table-striped order-items">
                <thead  className="text-center;">
                <tr>
                    <th>תמונה</th>
                    <th >שם המוצר</th>
                    <th>כמות</th>
                    <th>מחיר</th>
                    <th>סה"כ</th>
                </tr>
                </thead>
                <tbody>
                    {order.items.map((item) => {
                    return (
                        <tr key={item.itemId} >
                            <td className="image" ><img src={`${Constants.SITE_URL}${item.product.image}`} alt={item.product.productName}/></td>
                            <td  >
                                {item.product.productName}
                                <div className="smartphone notes text-primary">{item.notes}</div>
                                {item.product.special &&
                                item.ingredients.map((ing, idx) => {
                                    return (
                                    <div key={idx} className="text-success">{ing.name}</div>
                                    )
                                })}
                            </td>
            
                            <td >
                                {item.itemQtty} {item.unit.unitName}
                                <br/>
                                {item.notes}
                            </td>
                            <td >{item.product.price.toFixed(2)}</td>
                            <td >{(item.itemPrice).toFixed(2)}</td>
                        </tr>
                    )
                    })}
                    
                    <tr>
                        <td className="title" colSpan="2" >{order.items.length} מוצרים בהזמנה</td>       
                        <td className="title text-start" colSpan="2" >סה"כ קנייה:</td>
                        <td className="content">{order.totalCart.toFixed(2)}</td>
                    </tr>
                    {clubDiscount > 0 && 
                    <tr>
                        <td className="text-start" colSpan="4"> הנחת מועדון:</td>    
                        <td className="content" colSpan="3">{clubDiscount.toFixed(2)}-</td>
                    </tr>
                    }
                    { order.couponCode &&
                    <tr>
                        <td className="title text-start" colSpan="4" >קופון הנחה:</td>
                        <td className="content">{order.discount}-</td>
                    </tr>
                    }
                    { order.tip > 0  &&
                    <tr>
                        <td className="title text-start" colSpan="4" >טיפ:</td>
                        <td className="content">{order.tip.toFixed(2)}</td>
                    </tr>
                    }
                    { order.deliveryFee > 0 && 
                    <tr>
                        <td className="title text-start" colSpan="4" >משלוח:</td>
                        <td className="content">{order.deliveryFee.toFixed(2)}</td>
                    </tr>
                    }
                    <tr>
                        <td className="title text-start" colSpan="4">סה"כ לתשלום:</td>
                        <td className="content">{totalOrder && totalOrder.toFixed(2)} ₪</td>
                    </tr>
                </tbody>
            </table>
        )
    }


    return (
        <>
        <h3 className="m-5 text-center">פירוט הזמנה {orderid} </h3>
        <div className="container">
            <div className="row">
                <div className="col-md-3 vertical-nav">
                    <PersonalMenu />
                </div>
                <div className="col-md-8 bg-white p-3">
                    {order && <OrderHeader />}
                    <br></br>
                    {order && <OrderDetails /> }
                    <br></br>
                    {order && <OrderFooter /> }
                </div>
            </div>
        </div>
        </>
    )  
}

export default Order;