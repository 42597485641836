import React, { useState, useEffect } from "react";
import axios from "axios";
import Constants from "../Utilities/Constants";


const PageContent = ({furl}) => {
    const [page, setPage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const fetchAndSetPage = async () => {
            setIsLoading(true);
            const content = await fetchPage();
            setPage(content);
            setIsLoading(false);
        };
    
        fetchAndSetPage();
        
    }, [furl]);


    const fetchPage = async () => {
        var url = `${Constants.API_URL_GET_PAGE}/${furl}`;

        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            //alert("לא ניתן להתחבר לשרת");
            return null; 
        }
    };

    
      if (isLoading) {
        return <div>Loading...</div>;
    }
    
    const HtmlRenderer = ({ htmlContent }) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
      };


    return (
        page &&
            <HtmlRenderer htmlContent={page.pageContent} />
    )
}

export default PageContent;