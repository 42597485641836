import React from "react";
import Constants from "../Utilities/Constants";


const Payment = ({data, setComponent}) => { 

    console.log(data);
    const account = Constants.TRANZILA_ACCOUNT;
    const cred_type = 1;  // 8=payments
    const maxpay    = 1;
    const totalOrder= data.totalOrder.toFixed(2);
    const orderId   = data.orderId;
console.log('tranzila',account);
    return (
        
        <div className="p-5 m-3 rounded shadow bg-white">
            
            <div className="d-flex justify-content-between border-bottom border-success">
                <h4 className="">תשלום מאובטח</h4>
                <h4><button className="align-left" title="חזור" onClick={()=>setComponent('C')} >{'<<'}</button>
                </h4>
            </div>

            <div className="fw-bold text-success">
                <span>{totalOrder} ש"ח </span>
            </div>
            
            <iframe src={`https://direct.tranzila.com/${account}/iframenew.php?currency=1&cred_type=${cred_type}&maxpay=${maxpay}&tranmode=V&sum=${totalOrder}&orderid=${orderId}&lang=il&nologo=0&trButtonColor=da4498`} 
                className="ccard" title="Credit card">
            </iframe>
            
        </div>
    
    )
}

export default Payment;