import React from "react";


const SmartFooter = () => {
   
    return (
        <footer className="text-center">

            כל הזכויות שמורות לבליקמח
            <br/>
            <a href="mailto:ozovadia@gmail.com">בניית אתרים: <img src="img/oz_logo.png" style={{height:24}} alt="oz logo"/> עוז אביעד</a>
            | התמונות באתר להמחשה בלבד

        </footer>
    )
}


export default SmartFooter;