import React, { useState, useContext } from "react";
//import { useNavigate  } from "react-router-dom";
import Constants from "../Utilities/Constants";
import PersonalMenu from "../Components/PersonalMenu"
import { AppContext } from "../Components/AppContext";
import axios from "axios";


const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const {customer, setCustomer} = useContext(AppContext);
    //const navigate = useNavigate();


    if (customer === undefined) {
        alert('נא להיכנס למערכת');
        //navigate('/Login');
    }
    
    const updatePassword = async () => {
        
        if (await validateInput()) {
            
            console.log ('valid!');
            const hash = await hash256(password1);

            try {
                var customerId = customer.customerId;
                const url = Constants.API_URL_RESET_PASSWORD;
                const data = { customerId, hash };
                
                const response = await axios.post(url, data);
                setCustomer(response.data);

                success("הסיסמה שונתה בהצלחה!");
            }
            catch(error) {
                warning('סיסמה לא עודכנה');
            }
        }
    }
    
    const hash256 = async (text) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return sha256Hash;
    }

    function warning(msg) {
        var warning = document.getElementById('password-warning');
        warning.textContent = msg;
        warning.style.color = 'brown';
        warning.style.display = 'block';
    }
    
    function success(msg) {
        var warning = document.getElementById('password-warning');
        warning.textContent = msg;
        warning.style.color = 'green';
        warning.style.display = 'block';
    }

    async function validateInput() {

        var match = await comparePasswords(currentPassword,customer.password);
        console.log(match);
        if (!match) {
            warning("סיסמה נוכחית אינה תואמת");
            return false;
        }

        if (password1 !== password2) {
            warning("אין התאמה בין הססמאות");
            return false;
        }
        
        return true;
    }

    async function comparePasswords(newPassword,currentPassword) {
        
        const result = await hash256(newPassword);
        
        return ((result === currentPassword) ? true : false);
    }

    const PasswordForm = () => {

        return (

            <div className="container" >
                <div className="wrapper" >
                {/* <div className="w-50 p-5 rounded shadow bg-light" style={{backgroundColor: '#f2f2f2'}}> */}
    
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="current-password">סיסמה נוכחית</label>
                        <input type="password" id="current-password" name="current-password" className="form-control" 
                                onChange={(e)=>setCurrentPassword(e.target.value)} />
                    </div>
                    
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="password1">סיסמה חדשה</label>
                        <input type="password" id="password1" name="password1" className="form-control" autoComplete="new-password"
                                onChange={(e)=>setPassword1(e.target.value)} />
                    </div>
    
                    <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="password2">הקש שוב את הסיסמה</label>
                        <input type="password" id="password2" name="password2" className="form-control" autoComplete="new-password"
                                onChange={(e)=>setPassword2(e.target.value)} />
                    </div>
                    <div className="m-3" >
                        <span id="password-warning" className="invalid-feedback"></span>
                    </div>
    
                    <div className="d-grid mt-5">
                        <button type="button" className="btn btn-primary" 
                            onClick={()=>updatePassword()} >עדכן סיסמה</button>
                    </div>
    
                </div>
            </div>
        )
    
    }

    return (
        <>
        <h3 className="m-5 text-center">החלפת סיסמה</h3>
        <div className="container">
            <div className="row">
                <div className="col-md-3 vertical-nav">
                    <PersonalMenu />
                </div>
                <div className="col-md-9">
                    <PasswordForm />
                </div>
            </div>
        </div>
        </>
    )  
}


export default ChangePassword;