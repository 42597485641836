import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Constants from "../Utilities/Constants";
import { AppContext } from "./AppContext";


// Get ingredients for product from server
const fetchIngredientsData = async (productId) => {
    //console.log('fetching ingredients data');
    const url = `${Constants.API_URL_GET_INGREDIENTS}/${productId}`;
    const response = await axios.get(url);
console.log(response.data)    ;
    return response.data;
};

// Get selected ingredients. If user has already selected item return user selection from cart.
// Otherwise, get default items from igredients list.
const getSelectedIngredients = async (productId, cart, data) => {

    var cartitem = cart.find(i => i.productId === productId);

    if (cartitem && cartitem.selectedIngredients) {
        return JSON.parse(cartitem.selectedIngredients);
    } 
    else {
        const defaultIngredients = data
            .filter(ingredient => ingredient.default)
            .map(ingredient => ({ id: ingredient.ingredientId, ingredientName: ingredient.ingredientName }));
        return defaultIngredients;
    }
}

const getMaxItems = async (ingredients) => {
    const noOfDefaults = ingredients.filter(ingredient => ingredient.default).length;
    return noOfDefaults;
}


const IngredientsSelector = ({ productId }) => {
    const [ ingredients, setIngredients] = useState([]);
    const [ selectedIngredients, setSelectedIngredients] = useState([]);
    const [ maxItems, setMaxItems] = useState();
    const { cart, setCart, cartId } = useContext(AppContext);
  
    useEffect(() => {

        // Fetch ingredients data on component mount
        const getIngredientsData = async () => {
            const data = await fetchIngredientsData(productId);       
            setIngredients(data);
        };

        getIngredientsData();

    }, [productId]);

  
    useEffect(() => {

        const getIngredientsData = async () => {
            const defaultIngredients = await getSelectedIngredients(productId, cart, ingredients);
            setSelectedIngredients(defaultIngredients);
console.log(selectedIngredients);
            const noOfDefaults = await getMaxItems(ingredients);
            setMaxItems(noOfDefaults);
        };

        getIngredientsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart, ingredients]);


  const handleIngredientClick = (ingredient) => {

    if (selectedIngredients.length >= maxItems) {
        alert(`לא ניתן לבחור יותר מ-${maxItems} מרכיבים`);
    } else {
        var newlist = selectedIngredients.concat({ id: ingredient.ingredientId, ingredientName: ingredient.ingredientName });

        setSelectedIngredients(newlist);
        updateDB(newlist);
    }
    
  };

  const handleRemoveClick = (indexToRemove) => {
    var newlist =  selectedIngredients.filter((_, index) => index !== indexToRemove);
    
    setSelectedIngredients(newlist);
    updateDB(newlist);
  };

 
  const Ingredient = ({item}) => {
    return (
        <div className="d-flex ingredient shadow m-3 bg-light" role="button">
            <div className="image" id={item.ingredientId} 
                onClick={() => handleIngredientClick(item)}
                style={{backgroundImage: `url("${Constants.IMAGE_URL}${item.ingredientImage}")`, backgroundSize: 'cover', backgroundPosition: 'center'}}  alt={item.productName}></div>
            <div className="name m-2" onClick={() => handleIngredientClick(item)}>{item.ingredientName}</div>
        </div>
    )
  };

//   const selectedIdsString = selectedIngredients.map(ingredient => ingredient.id).join(',');

  if (!ingredients.length) {
    return <div>Loading...</div>;
  }


  async function updateDB(selectedIngs) {

    // Update db cart product with new qtty.
    // Receive updated cart product.
    // Update cart list with updated product.

    console.log('ingredients ids',selectedIngs);

    var item = cart.find(i => i.productId === productId);
    const apiUpdate = `${Constants.API_URL_UPDATE_CART}/${cartId}`;
    const data = {...item, selectedIngredients: JSON.stringify(selectedIngs)};

    console.log('data',data);
    const response = await axios.post(apiUpdate, data);
    const exists = cart.find((x) => x.productId === productId);
    
    if (exists) {
        handleUpdate(response.data);
    }
    else handleAdd(response.data);
}

async function handleUpdate(cartitem) {

    // Create new cart list with updated product
    const cartList = cart.map((x) => {
        return ((x.productId === cartitem.productId) ? cartitem : x);
    })

    setCart(cartList);
}

async function handleAdd(newItem) {
        
    // Concatenate new product to cart items
    const newList = [newItem, ...cart];
    setCart(newList);
}


  return (

    <div className="container" > 
        <div className="row" >
            <h4>המרכיבים שנבחרו (נא לבחור {maxItems} מרכיבים):</h4>
            <div className="col-md-5">
                <table className="table table-light table-striped">
                    <tbody>
                    {selectedIngredients.map((ingredient, index) => (
                    <tr key={index}>
                        <td >
                            {ingredient.ingredientName}
                        </td>
                        <td className="text-center">
                            <button className="remove" onClick={() => handleRemoveClick(index)}></button>
                        </td>
                    </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {/* <div className="col-md-7 text-center">
                <p>[{selectedIdsString}]</p>
                <p>{cartId}</p>
            </div> */}
        </div>
        <div className="row mt-2" >
            <h5 className="mb-4">באפשרותך לשנות את הרכב המוצר. לחץ על המוצרים שברצונך להוסיף:</h5>
            <div className="d-flex flex-wrap justify-content-around">
                {ingredients && ingredients.map((item, key) => {
                    return (
                        <Ingredient item={item} key={key} />
                    )
                })}
            </div>
        </div>
    </div>
);
};

export default IngredientsSelector;

