import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const SuccessPage = () => {
    const location = useLocation();
    const [data, setData] = useState({});

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const receivedData = {};
        for (let param of params.entries()) {
            receivedData[param[0]] = param[1];
        }
        setData(receivedData);
        console.log('Received data:', receivedData);
        // Perform any actions with the received data
    }, [location]);

    return (
        <div>
            <h1>Success Page</h1>
            <p>Data received from external site:</p>
            <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
    );
};

export default SuccessPage;
