import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Constants from "../Utilities/Constants";
import Product from "../Components/Product";
import Popup from "../Components/Popup";
import SideBarGallery from "../Components/SideBar";
import Banners from "../Components/Banners";


const HomePage = () => {
    
    const [ products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {

        if (!isMounted.current) {
            setIsLoading(true);

            fetchProducts();

            setIsLoading(false);
            console.log('reading data');

            return () => {
                isMounted.current = true;
            }
        } 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return <div>Loading...</div>;
    }
    

    const fetchProducts = async () => {
        
        var url = Constants.API_URL_GET_PRODUCT;
            
        try {
            const response = await axios.get(url);
            setProducts(response.data);
        }
        catch(error) {
            alert("לא ניתן להתחבר לשרת");
        }
    }


    return (
        <>
        <Banners galleryName="באנרים-דף-הבית" />
        <div className="container py-4">
            <div className="row">
                <div className="col-md-2 text-center desktop">
                    <div className="d-flex flex-wrap justify-content-around w-100">
                        <SideBarGallery />
                    </div>
                </div>

                <div className="col-md-10">
                    <div className="container py-4">
                        <h4 className="line-decoration">המומלצים שלנו</h4>
                        <br/>
                        <div className="d-flex flex-wrap justify-content-around">
                            {products.map((product, key) => {
                                return (
                                    <Product product={product} key={key} />
                                )
                            })}
                        </div>
                    </div>
                    <div className="container py-4 home-categories">
                        <h4 className="line-decoration">הקטגוריות שלנו</h4>
                            <div className="d-flex flex-wrap justify-content-around w-100">
                                <HomeBanners />
                            </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <Popup furl="פופאפ-דף-הבית" /> */}
        </>
    )
}

const HomeBanners = () => {
    const [ gallery, setGallery] = useState([]);

    useEffect(() => {

        const fetchGallery = async () => {
            var url = `${Constants.API_URL_GET_GALLERY}/קטגוריות-ראשיות`;

            try {
                const response = await axios.get(url)
                setGallery(response.data);
            }
            catch(error) {
                console.error("גלריה לא נמצאה");
            }
        }

        fetchGallery();
        
    },[])


    return (
        gallery.map((image, key) => {
            return (
                <Link to={image.imageLink} key={key} className="category-box" >
                    {/* <div className="p-5 border shadow category-box" 
                    style={{backgroundImage: `url("${Constants.IMAGE_URL}${image.fileName}")`, backgroundSize: 'cover'}}
                    alt={image.imageTitle}>
                        <span className="text-center position-absolute category-text">{image.imageTitle}</span>
                    </div> */}
                    <img src={`${Constants.IMAGE_URL}${image.fileName}`}  alt={image.imageTitle} />
                </Link>
            )
        })
    )
}


export default HomePage;
